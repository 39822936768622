

.customShadow{
  box-shadow: 0px 0px 24px 0px hsla(0, 0%, 100%, .5), 0px 0px 24px 0px rgba(29, 41, 57, .15);
}

.smallCustomShadow{
  box-shadow: 0px 0px 12px 0px hsla(0, 0%, 100%, .3), 0px 0px 12px 0px rgba(29, 41, 57, .1);
}

input:focus, select:focus, textarea:focus {
  outline: none;
  // box-shadow: 0 0 5px rgba(120, 120, 199, 0.8);
  box-shadow: none;
}

.fixedDiv{
  position: fixed;
  top: 0;
}

// .mainSec {
//   padding-top: 90px;
//   padding: 15px 0;
//   height: 100%;
//   margin-right: auto;
//   margin-left: auto;
//   display: flex;
//   flex-direction: column;
// }
.mainSec {
  width: 100%;
}
@media screen and (max-width: 767px){
  .mainSec {
    width: 100%;
    // padding: 15px 12px;
  }
}
@media (min-width: 768px) {
  .mainSec {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .mainSec {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .mainSec {
    width: 1200px;
  }
}

#my-applications-table{
  tr:not(:last-child){
    td{
      border-bottom: 1px solid rgb(209 213 219);
    }
  }
}

.fadeOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none;
}


$scrollbar-width: 8px;
::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
}
::-webkit-scrollbar-thumb {
  background: darkgray;
  border-radius: $scrollbar-width / 2;
}
::-webkit-scrollbar-track {
  background: #F3F3F3;
}

.descriptif ul {
  list-style-type: disc; /* Bullet points */
  padding-left: 20px; /* Indent items */
}

.descriptif ol {
  list-style-type: decimal; /* Numbered list */
  padding-left: 20px; /* Indent items */
}


.suggestion-list-item {
  &:hover {
    box-shadow: -12px 0 12px -12px hsla(0, 0%, 100%, .3), 12px 0 12px -12px rgba(29, 41, 57, .1);
  }
  
  &:not(:first-child) {
    border-left: 1px solid #E3E3E3;
  }

  &:first-child:hover {
    border-right: 1px solid var(--dynamic-color);
  }

  &:last-child:hover {
    border-left: 1px solid var(--dynamic-color);
  }

  &:hover:not(:first-child):not(:last-child) {
    border-left: 1px solid var(--dynamic-color);
    border-right: 1px solid var(--dynamic-color);
  }
}


.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}